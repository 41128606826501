import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebarlink";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { getAuthToken } from "../core/lib/localStorage";

function Home() {
  const friendOptions = [
    {
      key: "Jenny Hess",
      text: "Jenny Hess",
      value: "Jenny Hess",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/jenny.jpg",
      },
    },
    {
      key: "Elliot Fu",
      text: "Elliot Fu",
      value: "Elliot Fu",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/jenny.jpg",
      },
    },
    {
      key: "Stevie Feliciano",
      text: "Stevie Feliciano",
      value: "Stevie Feliciano",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/jenny.jpg",
      },
    },
    {
      key: "Christian",
      text: "Christian",
      value: "Christian",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/jenny.jpg",
      },
    },
    {
      key: "Matt",
      text: "Matt",
      value: "Matt",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/jenny.jpg",
      },
    },
    {
      key: "Justen Kitsune",
      text: "Justen Kitsune",
      value: "Justen Kitsune",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/jenny.jpg",
      },
    },
  ];

  const p2pFormValue = {
    qty: "",
    total: "",
  };

  const [loginCheck, setloginCheck] = useState(false);
  const [getP2POrders, setgetAllp2pOrders, getP2POrdersref] = useState([]);
  const [buyP2POrders, setbuyP2POrders, buyP2POrdersref] = useState([]);
  const [sellP2POrders, setsellP2POrders, sellP2POrdersref] = useState([]);
  const [buyOrders, setbuyOrders, buyOrdersref] = useState([]);
  const [sellOrders, setsellOrders, sellOrdersref] = useState([]);

  const [sendDatas, setSendDatas, sendDatasref] = useState("");
  const [show, setShow, showref] = useState(false);
  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCurrencyFiat, setallCurrencyFiat, allCurrencyFiatref] = useState(
    []
  );
  const [activetab, setActive, activetabref] = useState("BTC");
  const [activetype, setActivetype, activetyperef] = useState("buy");
  const [fiatCurrency, setfiatCurrency, fiatCurrencyref] = useState("");
  const [profileDatas, setprofileData, profileDatasref] = useState("");
  const [loginTrue, setloginTrue, loginTrueref] = useState(false);

  const [p2pformValue, setp2pFormValue, p2pformValueref] =
    useState(p2pFormValue);

  const { qty, total } = p2pformValue;
  const [p2pbalance, setp2pbalance, p2pbalanceref] = useState("");
  const [p2pData, setp2pData, p2pDataref] = useState("");

  const preferPayment = [
    { key: "", value: "", text: "Select" },
    { key: "All payments", value: "All payments", text: "All payments" },
    {
      key: "Bank Transfer",
      value: "Bank Transfer",
      text: "Bank Transfer",
    },
    { key: "UPI ID", value: "UPI ID", text: "UPI ID" },
    { key: "Paytm", value: "Paytm", text: "Paytm" },
  ];

  const [p2pAmount, setp2pAmount, p2pAmountref] = useState("");
  const [p2pPayment, setp2pPayment, p2pPaymentref] = useState("");
  const [loader, setloading] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    getAllp2pOrders();
    getAllcurrency();
    let user_token = getAuthToken();
    console.log("user_token===", typeof user_token);
    console.log("user_token.length===", user_token.length);
    if (user_token != "" && user_token != undefined && user_token != null) {
      setloginTrue(true);
      getProfile();
    } else {
      setloginTrue(false);
    }

    let userToken = localStorage.getItem("user_token");

    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
  }, []);

  const getProfile = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        setprofileData(resp.data);
      }
    } catch (error) { }
  };

  const getAllp2pOrders = async () => {
    var onj = {
      currency:
        fiatCurrencyref.current && fiatCurrencyref.current != "all"
          ? fiatCurrencyref.current
          : "",
    };
    var data = {
      apiUrl: apiService.p2pGetOrder,
      payload: onj,
    };
    var resp = await postMethod(data);
    console.log(resp.Message, "-=-=-resp=-=-");
    setloading(true);
    if (resp) {
      setloading(false);
      var data = resp.Message;
      setgetAllp2pOrders(resp.Message);
      var buy = data.filter((data) => data.orderType == "sell" && data.firstCurrency == activetabref.current);
      buy.sort(function (a, b) {
        return a.price - b.price;
      });
      console.log("buy orders---", buy);
      setbuyP2POrders(buy);
      setbuyOrders(buy);
      var sell = data.filter((data) => data.orderType == "buy" && data.firstCurrency == activetabref.current);
      sell.sort(function (a, b) {
        return b.price - a.price;
      });
      setsellP2POrders(sell);
      setsellOrders(sell);
    }
  };

  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.getP2Pcurrency,
    };
    var resp = await getMethod(data);
    if (resp) {
      var currArrayCrypto = [];
      // var currArrayFiat = [{value: "all", label: "Select Currency"}];
      var currArrayFiat = [];
      var data = resp.data;
      for (var i = 0; i < data.length; i++) {
        if (data[i].coinType == "1") {
          var obj = {
            id: data[i]._id,
            currencySymbol: data[i].currencySymbol,
          };
          currArrayCrypto.push(obj);
        }
        if (data[i].coinType == "2") {
          var obj = {
            key: data[i].currencySymbol,
            text: data[i].currencySymbol,
            value: data[i]._id,
            image: {
              avatar: true,
              src: data[i].Currency_image,
            },
          };
          currArrayFiat.push(obj);
        }
        console.log("currArrayFiat=====", currArrayFiat);
      }
      // var obj1 = {
      //   id: "All",
      //   currencySymbol: "All",
      // };
      // currArrayCrypto.push(obj1);

      setallCurrency(currArrayCrypto);
      setallCurrencyFiat(currArrayFiat);
    }
  };

  const defaulatCurrFiat = allCurrencyFiatref.current[0];

  const onSelect = async (option) => {
    console.log(option, "-=-onSelecttop");

    if (option.label == "Select Currency") {
      console.log("call currency all");
      setfiatCurrency(option.value);
      console.log("call currency all", fiatCurrencyref.current);
      getAllp2pOrders();
      setActive(activetabref.current);
      setActivetype("buy");
    } else {
      setfiatCurrency(option.label);
      var onj = {
        currency: option.label,
      };
      var data = {
        apiUrl: apiService.p2pGetOrder,
        payload: onj,
      };
      var resp = await postMethod(data);
      if (resp) {
        var data = resp.Message;
        setgetAllp2pOrders(resp.Message);
      }
    }
  };

  const handleChange = async (e) => {
    const newActiveTab = e.target.getAttribute("data-tab");
    console.log("newActiveTab===", newActiveTab);
    setActive(newActiveTab);
    getAllp2pOrders();
    console.log("activetabref.current===", activetabref.current);
  };

  const handleType = async (e) => {
    const active_type = e.target.getAttribute("data-tab");
    setActivetype(active_type);
  };

  const getp2pOrder = async (data) => {
    setp2pData(data);
    var onj = {
      fromCurrency: data.fromCurrency,
    };
    var data = {
      apiUrl: apiService.getp2pBalance,
      payload: onj,
    };
    var resp = await postMethod(data);
    console.log(resp.Message, "-=-=-resp=-=-");
    if (resp) {
      var data = resp.Message;
      setp2pbalance(resp.p2pbalance);
    }
  };

  const confirm_handleChange = async (e) => {
    console.log("e====", e);
    e.preventDefault();
    const { name, value } = e.target;
    let p2pformData = { ...p2pformValue, ...{ [name]: value } };
    setp2pFormValue(p2pformData);
    console.log("p2pformValueref.current.qty===", p2pformValueref.current.qty);
    if (p2pformValueref.current.qty > 0) {
      var order_qty = p2pformValueref.current.qty;
      var min_qty = p2pDataref.current.fromLimit;
      var max_qty = p2pDataref.current.toLimit;
      console.log("min_qty===", min_qty);
      console.log("max_qty===", max_qty);
      console.log("order_qty===", order_qty);
      if (
        +order_qty < p2pDataref.current.fromLimit ||
        +order_qty > p2pDataref.current.toLimit
      ) {
        toast.error(
          "Please enter quantity between " + min_qty + " and " + max_qty + ""
        );
        console.log(p2pDataref.current.fromLimit, "=-=-=-", +order_qty)
        p2pformValueref.current.qty = "";
        p2pformValueref.current.total = "";
      } else {
        p2pformValueref.current.total = parseFloat(
          +order_qty * p2pDataref.current.price
        ).toFixed(2);
        console.log("p2pformData====", p2pformValueref.current);
      }
    } else {
      toast.error("Please enter valid quantity");
    }
  };

  const confirm_order_buy = async () => {
    try {
      var obj = {};
      obj.qty = p2pformValueref.current.qty;
      obj.total = p2pformValueref.current.total;
      obj.orderId = p2pDataref.current.orderId;
      obj.p2porderId = p2pDataref.current._id;
      obj.type = "buy";

      if (obj.qty != "" || obj.total != "") {
        var data = {
          apiUrl: apiService.p2p_confirm_order,
          payload: obj,
        };
        var resp = await postMethod(data);
        if (resp.status) {
          toast.success(resp.Message);
          //navigate(resp.link);
          window.location.href = resp.link;
        } else {
          toast.error(resp.Message);
        }
      } else {
        toast.error("Please enter quantity");
      }
    } catch (error) { }
  };

  const confirm_order_sell = async () => {
    try {
      var obj = {};
      obj.qty = p2pformValueref.current.qty;
      obj.total = p2pformValueref.current.total;
      obj.orderId = p2pDataref.current.orderId;
      obj.p2porderId = p2pDataref.current._id;
      obj.type = "sell";

      if (obj.qty != "" || obj.total != "") {
        var data = {
          apiUrl: apiService.p2p_confirm_sellorder,
          payload: obj,
        };
        var resp = await postMethod(data);
        if (resp.status) {
          toast.success(resp.Message);
          //navigate(resp.link);
          window.location.href = resp.link;
        } else {
          toast.error(resp.Message);
        }
      } else {
        toast.error("Please enter quantity");
      }
    } catch (error) { }
  };

  const maximum_buy = async () => {
    var order_qty =
      +p2pDataref.current.totalAmount - +p2pDataref.current.processAmount;
    console.log("order_qty====", order_qty);
    p2pformValueref.current.qty = order_qty;
    p2pformValueref.current.total = order_qty * p2pDataref.current.price;
    var obj = {
      qty: order_qty,
      total: parseFloat(order_qty * p2pDataref.current.price).toFixed(2),
    };
    setp2pFormValue(obj);
    console.log("p2pformValueref.current====", p2pformValueref.current);
  };

  const maximum_sell = async () => {
    var avail_qty =
      +p2pDataref.current.totalAmount - +p2pDataref.current.processAmount;
    var order_qty =
      avail_qty > p2pbalanceref.current ? p2pbalanceref.current : avail_qty;
    console.log("order_qty====", order_qty);
    p2pformValueref.current.qty = order_qty;
    p2pformValueref.current.total = order_qty * p2pDataref.current.price;
    var obj = {
      qty: order_qty,
      total: parseFloat(order_qty * p2pDataref.current.price).toFixed(2),
    };
    setp2pFormValue(obj);
    console.log("p2pformValueref.current====", p2pformValueref.current);
  };

  const handlePayment = async (e, data) => {
    setp2pPayment(data.value);
    filter_p2p();
  };

  const handleAmount = async (e) => {
    console.log(e, "-=-=-=-=-");
    e.preventDefault();
    setp2pAmount(e.target.value);
    filter_p2p();
  };

  const filter_p2p = async () => {
    console.log("p2pAmountref.current", p2pAmountref.current)
    console.log("p2pPaymentref.current", p2pPaymentref.current)
    if (p2pAmountref.current != "" && p2pPaymentref.current != "Select") {
      console.log("call here")
      if (activetyperef.current == "buy") {
        console.log("call buy")
        const orders = buyOrdersref.current.filter((str) => {
          return str.paymentMethod.indexOf(p2pPaymentref.current) >= 0 && str.price == p2pAmountref.current;
        });
        console.log("orders===", orders);
        setbuyP2POrders(orders);
      }
      else {
        console.log("call sell")
        const orders = sellOrdersref.current.filter((str) => {
          return str.paymentMethod.indexOf(p2pPaymentref.current) >= 0 && str.price == p2pAmountref.current;
        });
        console.log("orders===", orders);
        setsellP2POrders(orders);
      }
    }
    else if (p2pAmountref.current != "") {
      console.log("call here 11")
      if (activetyperef.current == "buy") {
        console.log("call buy")
        const orders = buyOrdersref.current.filter((str) => {
          return str.price == p2pAmountref.current;
        });
        console.log("orders===", orders);
        setbuyP2POrders(orders);
      }
      else {
        console.log("call sell")
        const orders = sellOrdersref.current.filter((str) => {
          return str.price == p2pAmountref.current;
        });
        console.log("orders===", orders);
        setsellP2POrders(orders);
      }
    }
    else if (p2pPaymentref.current != "Select") {
      console.log("call here 22")
      if (activetyperef.current == "buy") {
        console.log("call buy")
        const orders = buyOrdersref.current.filter((str) => {
          return str.paymentMethod.indexOf(p2pPaymentref.current) >= 0;
        });
        console.log("orders===", orders);
        setbuyP2POrders(orders);
      }
      else {
        console.log("call sell")
        const orders = sellOrdersref.current.filter((str) => {
          return str.paymentMethod.indexOf(p2pPaymentref.current) >= 0;
        });
        console.log("orders===", orders);
        setsellP2POrders(orders);
      }
    }
    else {
      console.log("call there")
      getAllp2pOrders();
    }

  }

  const refresh_data = async () => {
    getAllp2pOrders();
  };


  return (
    <div className="header ">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          {loader == true ? (
            <div class="spinner css-ezm1fk">
              <div class="css-1qoumk2"></div>
              <div class="css-1v19680"></div>
              <div class="css-1v90jie"></div>
              <div class="css-19p0rvp"></div>
            </div>
          ) : (
            <div className="css-1h690ep">
              <div className="section_bg_ash">
                <div className="section_selec_ash">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12">
                        <h2 className="heading_titlr">
                          Buy and Sell Crypto With Your Preferred Payment Method
                        </h2>
                        <p className="proildw_set">
                          Buy and sell Crypto safely and easily on Adverb P2P.
                          Find the best offer below and buy and sell Crypto with
                          Your Preferred Payment Methods today.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inner_conteten">
                  <div className="select_option">
                    <div className="tabs_section justify-content-between">
                      <div className="d-flex">
                        <div>
                          <ul class="nav nav-tabs">
                            <li class="active">
                              <a
                                data-toggle="tab"
                                href="#buy"
                                className="active buy"
                                data-tab="buy"
                                onClick={handleType}
                              >
                                {" "}
                                Buy
                              </a>
                            </li>
                            <li>
                              <a data-toggle="tab" href="#sell" className=" sell" data-tab="sell" onClick={handleType}>
                                Sell
                              </a>
                            </li>
                          </ul>
                        </div>

                      </div>
                      <div>
                        <div class="dropdown butn_riw_ss History-button">
                          <button class="refresg_button dropdown-toggle " type="button" data-toggle="dropdown">History
                            <span class="caret"></span></button>
                          <ul class="dropdown-menu">
                            <li><a href="/p2p-processOrders">Process Orders</a></li>
                            <li><a href="/p2p-Orders">My Orders</a></li>
                            <li><a href="/p2p-History">My History</a></li>
                            <li><a href="/p2p/postAd">Post Ad</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tabs_contnte_section">
                    <div class="tab-content shadow my-4 p-4 border-rounded">
                      <>
                        <div id="buy" class="tab-pane fade in active show">
                          <div className="filter_section justify-content-between">
                            <div className="tabs_section">
                              <div className="tabs_coin_section">

                                <ul class="nav nav-tabs newtabs">
                                  {allCurrencyref.current &&
                                    allCurrencyref.current.map((item, i) => {
                                      var classactive = "";
                                      if (item.currencySymbol == activetabref.current) {
                                        classactive = "active";
                                      }
                                      return (
                                        <>
                                          <li class={`${classactive}`}>
                                            <a
                                              data-toggle="tab"
                                              data-tab={`${item.currencySymbol}`}
                                              href={`#${item.currencySymbol}`}
                                              className={`${classactive}`}
                                              onClick={handleChange}
                                            >
                                              {" "}
                                              {item.currencySymbol}
                                            </a>
                                          </li>
                                        </>
                                      );
                                    })}
                                </ul>

                                {/* <ul class="nav nav-tabs newtabs">
                          <li class="active">
                            <a
                              data-toggle="tab"
                              href="#BTC"
                              className="active "
                            >
                              {" "}
                              BTC
                            </a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#USDT" className=" ">
                              USDT
                            </a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#ETH" className=" ">
                              ETH
                            </a>
                          </li>
                        </ul> */}
                              </div>
                            </div>
                            <div className="filter_section">
                              <div className="Filter_section_input">
                                <div className="input_section_new">
                                  <label>Price</label>
                                  <input type="text" placeholder="" onChange={handleAmount} />
                                </div>
                              </div>
                              <div className="Filter_section_input">
                                <div className="input_section_new">
                                  <label>Fiat</label>
                                  <Dropdown
                                    placeholder="Select"
                                    fluid
                                    selection
                                    className="data_dropdoen"
                                    options={allCurrencyFiatref.current}

                                  />
                                </div>
                              </div>
                              <div className="Filter_section_input">
                                <div className="input_section_new">
                                  <label>Payment Method</label>
                                  <Dropdown
                                    fluid
                                    selection
                                    className="data_dropdoen"
                                    options={preferPayment}
                                    onChange={handlePayment}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="button-check">
                                <Button className="refresg_button" onClick={refresh_data}>
                                  <i class="bi bi-arrow-clockwise"></i> refresh
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div class="tab-content  ">
                            {allCurrencyref.current &&
                              allCurrencyref.current.map((item, j) => {
                                return (
                                  <div id={`${item.currencySymbol}`} class={`tab-pane fade in ${activetabref.current == item.currencySymbol ? "active show" : ""}`}>
                                    <div className="action_bootrsebe_tanbel">
                                      <div className="table-responsive mt-4 history_table table-content">
                                        <table className="table">
                                          <thead>
                                            <tr>
                                              <th>Advertisers </th>
                                              <th>Price</th>
                                              <th>Limit/Available</th>
                                              <th>Payment</th>
                                              <th>Trade</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {buyP2POrdersref.current.length > 0 ? (
                                              buyP2POrdersref.current.map(
                                                (orders, i) => {
                                                  let available =
                                                    orders.totalAmount -
                                                    +orders.processAmount;
                                                  return orders.firstCurrency ==
                                                    activetabref.current &&
                                                    available > 0 ? (
                                                    <>
                                                      {/* <tr
                                              data-toggle="collapse"
                                              data-target={`#demo${orders._id}`}
                                              class="accordion-toggle"
                                            > */}
                                                      <tr>
                                                        <td>
                                                          <div className="profile_namepe">
                                                            <span>{orders.username.charAt(0).toUpperCase()}</span>
                                                            <div className="details">
                                                              <p>{orders.username}</p>
                                                              {/* <small>
                                                  16 orders | 100.00% completion
                                                </small> */}
                                                            </div>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className="price_">
                                                            <p>
                                                              {orders && orders.price} <span>{orders && orders.secondCurrnecy}</span>
                                                            </p>
                                                          </div>
                                                        </td>

                                                        <td>
                                                          <div className="avli_limit">
                                                            <div className="price_">
                                                              <p>
                                                                <span>Available</span> {orders &&
                                                                  orders.totalAmount -
                                                                  +orders.processAmount}{" "}
                                                                {orders &&
                                                                  orders.firstCurrency}
                                                              </p>
                                                            </div>
                                                            <div className="price_">
                                                              {/* <p>
                                                  <span>Limit</span> ₹18,500.00 - ₹
                                                  59,779.09
                                                </p> */}
                                                              <p>
                                                                <span>Limit</span> {orders &&
                                                                  orders.fromLimit}{" - "}
                                                                {orders &&
                                                                  orders.toLimit} {" "} {orders &&
                                                                    orders.firstCurrency}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </td>

                                                        <td>
                                                          <div className="playment_methoid">
                                                            {orders && orders.paymentMethod}
                                                          </div>
                                                        </td>
                                                        {loginTrue == true ? (
                                                          profileDatasref.current &&
                                                            profileDatasref.current._id.toString() ==
                                                            orders.userId.toString() ? (
                                                            <td>
                                                              <Link
                                                                to={`/p2p/view/${orders.orderId}`}
                                                              >
                                                                <Button className="btn_buy">
                                                                  {" "}
                                                                  View{" "}
                                                                </Button>{" "}
                                                              </Link>
                                                            </td>
                                                          ) : (
                                                            <td data-toggle="collapse"
                                                              data-target={`#demo${orders._id}`}
                                                              class="accordion-toggle">
                                                              <Button className="btn_buy" onClick={() => getp2pOrder(orders)}>
                                                                Buy {orders && orders.firstCurrency}
                                                              </Button>
                                                            </td>
                                                          )
                                                        ) : (
                                                          <td>
                                                            <Link to={"/login"}>
                                                              <Button className="btn_buy">
                                                                {" "}
                                                                {orders &&
                                                                  orders.orderType ==
                                                                  "buy"
                                                                  ? "sell"
                                                                  : "buy"}{" "}
                                                              </Button>
                                                            </Link>
                                                          </td>
                                                        )}
                                                      </tr>
                                                      <tr>
                                                        <td colSpan="5" className="paddingh_0">
                                                          <div
                                                            class="accordian-body collapse"
                                                            id={`demo${orders._id}`}
                                                          >
                                                            {" "}
                                                            <div className="chat_detaile ros">
                                                              <div className="row">
                                                                <div className="col-lg-7 d-flex flex-column justify-content-between">
                                                                  <div className="row">
                                                                    <div className="col-lg-6">
                                                                      <div className="price_detaile">
                                                                        <p>
                                                                          Price
                                                                          <span className="text-red">
                                                                            {orders && orders.price} {" "} {orders && orders.secondCurrnecy}
                                                                          </span>
                                                                        </p>
                                                                        <p>
                                                                          Payment
                                                                          <span className="">
                                                                            {orders && orders.pay_time} Minutes
                                                                          </span>
                                                                        </p>
                                                                      </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                      <div className="price_detaile">
                                                                        <p>
                                                                          Available
                                                                          <span className="text-red">
                                                                            {orders &&
                                                                              orders.totalAmount -
                                                                              +orders.processAmount}{" "}
                                                                            {orders &&
                                                                              orders.firstCurrency}
                                                                          </span>
                                                                        </p>
                                                                        <p>
                                                                          Payment
                                                                          {/* <div className="payment_types">
                                                                <small>Phonepe</small>
                                                                <small>
                                                                  Google pay(gpay)
                                                                </small>
                                                              </div> */}
                                                                          <div className="payment_types">
                                                                            <small>{orders && orders.paymentMethod}</small>
                                                                          </div>
                                                                        </p>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="row">
                                                                    <div className="col-lg-12">
                                                                      <div className="terms_dess">
                                                                        <h5>
                                                                          Terms and Conditions
                                                                        </h5>
                                                                        <ul>
                                                                          <li>
                                                                            {orders && orders.terms}
                                                                          </li>
                                                                        </ul>
                                                                        {/* <ul>
                                                              <li>
                                                                Corem ipsum dolor sit
                                                                amet, consectetur
                                                                adipiscing elit. Nunc
                                                                vulputate libero et
                                                                velit interdum
                                                              </li>
                                                              <li>
                                                                Korem ipsum dolor sit
                                                                amet, consectetur
                                                                adipiscing elit. Nunc
                                                                vulputate libero et
                                                                <br />
                                                                velit interdum, ac
                                                                aliquet odio mattis.
                                                              </li>
                                                            </ul> */}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="col-lg-5">
                                                                  <div className="class_form_section">
                                                                    <div className="form_p2p mb-4">
                                                                      <label>Enter quantity to buy</label>
                                                                      <div className="input_row_q">
                                                                        <input type="text" name="qty" value={qty} onChange={confirm_handleChange} />
                                                                        <div>
                                                                          {/* <small>All</small> */}
                                                                          <span>{orders && orders.firstCurrency}</span>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    <div className="form_p2p mb-4">
                                                                      <label>You will pay</label>
                                                                      <div className="input_row_q">
                                                                        <input type="text" name="total" value={total} onChange={confirm_handleChange} />
                                                                        <div>
                                                                          <span>{orders && orders.secondCurrnecy}</span>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    {/* <div className="form_p2p mb-4">
                                                          <label>Paymet Method</label>
                                                          <div className="selct_pay">
                                                            <Dropdown
                                                              placeholder="Select Friend"
                                                              fluid
                                                              selection
                                                              className="data_dropdoen"
                                                              options={friendOptions}
                                                            />
                                                          </div>
                                                        </div> */}
                                                                    <div className="button_cancel">
                                                                      <Button>Cancel</Button>
                                                                      <Button className="color_section" onClick={confirm_order_buy}>
                                                                        Buy {orders && orders.firstCurrency}
                                                                      </Button>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </>

                                                  ) : (
                                                    // <td colspan="5">
                                                    //   <div class="norecode_found">
                                                    //     <span>No records found 222</span>
                                                    //   </div>
                                                    // </td>
                                                    " "
                                                  );
                                                }
                                              )
                                            ) : (
                                              <tr>
                                                <td colspan="5" className="text-center">
                                                  <p>No records found</p>
                                                </td>
                                              </tr>

                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <div id="sell" class="tab-pane fade">
                          <div className="filter_section justify-content-between">
                          <div className="tabs_section">
                              <div className="tabs_coin_section">

                                <ul class="nav nav-tabs newtabs">
                                  {allCurrencyref.current &&
                                    allCurrencyref.current.map((item, i) => {
                                      var classactive = "";
                                      if (item.currencySymbol == activetabref.current) {
                                        classactive = "active";
                                      }
                                      return (
                                        <>
                                          <li class={`${classactive}`}>
                                            <a
                                              data-toggle="tab"
                                              data-tab={`${item.currencySymbol}`}
                                              href={`#${item.currencySymbol}`}
                                              className={`${classactive}`}
                                              onClick={handleChange}
                                            >
                                              {" "}
                                              {item.currencySymbol}
                                            </a>
                                          </li>
                                        </>
                                      );
                                    })}
                                </ul>

                                {/* <ul class="nav nav-tabs newtabs">
                          <li class="active">
                            <a
                              data-toggle="tab"
                              href="#BTC"
                              className="active "
                            >
                              {" "}
                              BTC
                            </a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#USDT" className=" ">
                              USDT
                            </a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#ETH" className=" ">
                              ETH
                            </a>
                          </li>
                        </ul> */}
                              </div>
                            </div>
                            <div className="filter_section">
                              <div className="Filter_section_input">
                                <div className="input_section_new">
                                  <label>Price</label>
                                  <input type="text" placeholder="" onChange={handleAmount} />
                                </div>
                              </div>
                              <div className="Filter_section_input">
                                <div className="input_section_new">
                                  <label>Fiat</label>
                                  <Dropdown
                                    placeholder="Select"
                                    fluid
                                    selection
                                    className="data_dropdoen"
                                    options={allCurrencyFiatref.current}
                                  />
                                </div>
                              </div>
                              <div className="Filter_section_input">
                                <div className="input_section_new">
                                  <label>Payment Method</label>
                                  <Dropdown
                                    fluid
                                    selection
                                    className="data_dropdoen"
                                    options={preferPayment}
                                    onChange={handlePayment}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <Button className="refresg_button" onClick={refresh_data}>
                                <i class="bi bi-arrow-clockwise"></i> refresh
                              </Button>
                            </div>
                          </div>
                          <div class="tab-content ">
                            {allCurrencyref.current &&
                              allCurrencyref.current.map((item, j) => {
                                return (
                                  <div id={`${item.currencySymbol}`} class={`tab-pane fade in ${activetabref.current == item.currencySymbol ? "active show" : ""}`}>
                                    <div className="action_bootrsebe_tanbel">
                                      <div className="table-responsive mt-4 history_table">
                                        <table className="table">
                                          <thead>
                                            <tr>
                                              <th>Advertisers </th>
                                              <th>Price</th>
                                              <th>Limit/Available</th>
                                              <th>Payment</th>
                                              <th>Trade</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {sellP2POrdersref.current.length > 0 ? (
                                              sellP2POrdersref.current.map(
                                                (orders, i) => {
                                                  let available =
                                                    orders.totalAmount -
                                                    +orders.processAmount;
                                                  return orders.firstCurrency ==
                                                    activetabref.current &&
                                                    available > 0 ? (
                                                    <>
                                                      {/* <tr
                                              data-toggle="collapse"
                                              data-target={`#demo${orders._id}`}
                                              class="accordion-toggle"
                                            > */}
                                                      <tr>
                                                        <td>
                                                          <div className="profile_namepe">
                                                            <span>{orders.username.charAt(0).toUpperCase()}</span>
                                                            <div className="details">
                                                              <p>{orders.username}</p>
                                                              {/* <small>
                                                  16 orders | 100.00% completion
                                                </small> */}
                                                            </div>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className="price_">
                                                            <p>
                                                              {orders && orders.price} <span>{orders && orders.secondCurrnecy}</span>
                                                            </p>
                                                          </div>
                                                        </td>

                                                        <td>
                                                          <div className="avli_limit">
                                                            <div className="price_">
                                                              <p>
                                                                <span>Available</span> {orders &&
                                                                  orders.totalAmount -
                                                                  +orders.processAmount}{" "}
                                                                {orders &&
                                                                  orders.firstCurrency}
                                                              </p>
                                                            </div>
                                                            <div className="price_">
                                                              {/* <p>
                                                  <span>Limit</span> ₹18,500.00 - ₹
                                                  59,779.09
                                                </p> */}
                                                              <p>
                                                                <span>Limit</span> {orders &&
                                                                  orders.fromLimit}{" - "}
                                                                {orders &&
                                                                  orders.toLimit} {" "} {orders &&
                                                                    orders.firstCurrency}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </td>

                                                        <td>
                                                          <div className="playment_methoid">
                                                            {orders && orders.paymentMethod}
                                                          </div>
                                                        </td>
                                                        {loginTrue == true ? (
                                                          profileDatasref.current &&
                                                            profileDatasref.current._id.toString() ==
                                                            orders.userId.toString() ? (
                                                            <td>
                                                              <Link
                                                                to={`/p2p/view/${orders.orderId}`}
                                                              >
                                                                <Button className="btn_sell">
                                                                  {" "}
                                                                  View{" "}
                                                                </Button>{" "}
                                                              </Link>
                                                            </td>
                                                          ) : (
                                                            <td data-toggle="collapse"
                                                              data-target={`#demo${orders._id}`}
                                                              class="accordion-toggle">
                                                              <Button className="btn_sell" onClick={() => getp2pOrder(orders)}>
                                                                Sell {orders && orders.firstCurrency}
                                                              </Button>
                                                            </td>
                                                          )
                                                        ) : (
                                                          <td>
                                                            <Link to={"/login"}>
                                                              <Button className="btn_sell">
                                                                {" "}
                                                                {orders &&
                                                                  orders.orderType ==
                                                                  "buy"
                                                                  ? "sell"
                                                                  : "buy"}{" "}
                                                              </Button>
                                                            </Link>
                                                          </td>
                                                        )}
                                                      </tr>
                                                      <tr>
                                                        <td colSpan="5" className="paddingh_0">
                                                          <div
                                                            class="accordian-body collapse"
                                                            id={`demo${orders._id}`}
                                                          >
                                                            {" "}
                                                            <div className="chat_detaile ros">
                                                              <div className="row">
                                                                <div className="col-lg-7 d-flex flex-column justify-content-between">
                                                                  <div className="row">
                                                                    <div className="col-lg-6">
                                                                      <div className="price_detaile">
                                                                        <p>
                                                                          Price
                                                                          <span className="text-red">
                                                                            {orders && orders.price} {" "} {orders && orders.secondCurrnecy}
                                                                          </span>
                                                                        </p>
                                                                        <p>
                                                                          Payment
                                                                          <span className="">
                                                                            {orders && orders.pay_time} Minutes
                                                                          </span>
                                                                        </p>
                                                                      </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                      <div className="price_detaile">
                                                                        <p>
                                                                          Available
                                                                          <span className="text-red">
                                                                            {orders &&
                                                                              orders.totalAmount -
                                                                              +orders.processAmount}{" "}
                                                                            {orders &&
                                                                              orders.firstCurrency}
                                                                          </span>
                                                                        </p>
                                                                        <p>
                                                                          Payment
                                                                          {/* <div className="payment_types">
                                                                <small>Phonepe</small>
                                                                <small>
                                                                  Google pay(gpay)
                                                                </small>
                                                              </div> */}
                                                                          <div className="payment_types">
                                                                            <small>{orders && orders.paymentMethod}</small>
                                                                          </div>
                                                                        </p>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="row">
                                                                    <div className="col-lg-12">
                                                                      <div className="terms_dess">
                                                                        <h5>
                                                                          Terms and Conditions
                                                                        </h5>
                                                                        <ul>
                                                                          <li>
                                                                            {orders && orders.terms}
                                                                          </li>
                                                                        </ul>
                                                                        {/* <ul>
                                                              <li>
                                                                Corem ipsum dolor sit
                                                                amet, consectetur
                                                                adipiscing elit. Nunc
                                                                vulputate libero et
                                                                velit interdum
                                                              </li>
                                                              <li>
                                                                Korem ipsum dolor sit
                                                                amet, consectetur
                                                                adipiscing elit. Nunc
                                                                vulputate libero et
                                                                <br />
                                                                velit interdum, ac
                                                                aliquet odio mattis.
                                                              </li>
                                                            </ul> */}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="col-lg-5">
                                                                  <div className="class_form_section">
                                                                    <div className="form_p2p mb-4">
                                                                      <label>Enter quantity to sell</label>
                                                                      <div className="input_row_q">
                                                                        <input type="text" name="qty" value={qty} onChange={confirm_handleChange} />
                                                                        <div>
                                                                          {/* <small>All</small> */}
                                                                          <span>{orders && orders.firstCurrency}</span>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    <div className="form_p2p mb-4">
                                                                      <label>You will pay</label>
                                                                      <div className="input_row_q">
                                                                        <input type="text" name="total" value={total} onChange={confirm_handleChange} />
                                                                        <div>
                                                                          <span>{orders && orders.secondCurrnecy}</span>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    {/* <div className="form_p2p mb-4">
                                                          <label>Paymet Method</label>
                                                          <div className="selct_pay">
                                                            <Dropdown
                                                              placeholder="Select Friend"
                                                              fluid
                                                              selection
                                                              className="data_dropdoen"
                                                              options={friendOptions}
                                                            />
                                                          </div>
                                                        </div> */}
                                                                    <div className="button_cancel">
                                                                      <Button>Cancel</Button>
                                                                      <Button className="color_section" onClick={confirm_order_sell}>
                                                                        Sell {orders && orders.firstCurrency}
                                                                      </Button>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </>

                                                  ) : (
                                                    // <td colspan="5">
                                                    //   <div class="norecode_found">
                                                    //     <span>No records found 111</span>
                                                    //   </div>
                                                    // </td>
                                                    " "
                                                  );
                                                }
                                              )
                                            ) : (<tr>
                                              <td colspan="5" className="text-center">
                                                <p>No records found</p>
                                              </td>
                                            </tr>)}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </>

                      {/* <div id="buy" class="tab-pane fade in active show">
                      <div className="filter_section justify-content-between">
                        <div className="filter_section">
                          <div className="Filter_section_input">
                            <div className="input_section_new">
                              <label>Amount</label>
                              <input type="text" placeholder="" />
                            </div>
                          </div>
                          <div className="Filter_section_input">
                            <div className="input_section_new">
                              <label>Fiat</label>
                              <Dropdown
                                placeholder="Select Friend"
                                fluid
                                selection
                                className="data_dropdoen"
                                options={friendOptions}
                              />
                            </div>
                          </div>
                          <div className="Filter_section_input">
                            <div className="input_section_new">
                              <label>Payment Method</label>
                              <Dropdown
                                placeholder="Select Friend"
                                fluid
                                selection
                                className="data_dropdoen"
                                options={friendOptions}
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <Button className="refresg_button">
                            <i class="bi bi-arrow-clockwise"></i> refresh
                          </Button>
                        </div>
                      </div>
                      <div class="tab-content shadow my-4 p-4 border-rounded">
                        <div id="BTC" class="tab-pane fade in active show">
                          <div className="action_bootrsebe_tanbel">
                            <div className="table-responsive mt-4 history_table">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Advertisers </th>
                                    <th>Price</th>
                                    <th>Limit/Available</th>
                                    <th>Payment</th>
                                    <th>Trade</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    data-toggle="collapse"
                                    data-target="#demo1"
                                    class="accordion-toggle"
                                  >
                                    {/* <td colspan="7">
                                      <div class="norecode_found">
                                        <span>No records found</span>
                                      </div>
                                    </td> */}

                      {/* <td>
                                      <div className="profile_namepe">
                                        <span>J</span>
                                        <div className="details">
                                          <p>junawasarkar</p>
                                          <small>
                                            16 orders | 100.00% completion
                                          </small>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="price_">
                                        <p>
                                          80.50 <span>INR</span>
                                        </p>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="avli_limit">
                                        <div className="price_">
                                          <p>
                                            <span>Available</span> 675.47 USDT
                                          </p>
                                        </div>
                                        <div className="price_">
                                          <p>
                                            <span>Limit</span> ₹18,500.00 - ₹
                                            59,779.09
                                          </p>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="playment_methoid">
                                        Bank Transfer
                                      </div>
                                    </td>

                                    <td>
                                      <Button className="btn_buy">
                                        Buy BTC
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="5" className="paddingh_0">
                                      <div
                                        class="accordian-body collapse"
                                        id="demo1"
                                      >
                                        {" "}
                                        <div className="chat_detaile">
                                          <div className="row">
                                            <div className="col-lg-7 d-flex flex-column justify-content-between">
                                              <div className="row">
                                                <div className="col-lg-6">
                                                  <div className="price_detaile">
                                                    <p>
                                                      Price
                                                      <span className="text-red">
                                                        100 INR
                                                      </span>
                                                    </p>
                                                    <p>
                                                      Payment
                                                      <span className="">
                                                        15 Minutes
                                                      </span>
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="col-lg-6">
                                                  <div className="price_detaile">
                                                    <p>
                                                      Available
                                                      <span className="text-red">
                                                        0.001 USDT
                                                      </span>
                                                    </p>
                                                    <p>
                                                      Payment
                                                      <div className="payment_types">
                                                        <small>Phonepe</small>
                                                        <small>
                                                          Google pay(gpay)
                                                        </small>
                                                      </div>
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-lg-12">
                                                  <div className="terms_dess">
                                                    <h5>
                                                      Terms and Conditions
                                                    </h5>
                                                    <ul>
                                                      <li>
                                                        Corem ipsum dolor sit
                                                        amet, consectetur
                                                        adipiscing elit. Nunc
                                                        vulputate libero et
                                                        velit interdum
                                                      </li>
                                                      <li>
                                                        Korem ipsum dolor sit
                                                        amet, consectetur
                                                        adipiscing elit. Nunc
                                                        vulputate libero et
                                                        <br />
                                                        velit interdum, ac
                                                        aliquet odio mattis.
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-lg-5">
                                              <div className="class_form_section">
                                                <div className="form_p2p mb-4">
                                                  <label>I want to bay</label>
                                                  <div className="input_row_q">
                                                    <input type="text" />
                                                    <div>
                                                      <small>All</small>
                                                      <span>USDT</span>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form_p2p mb-4">
                                                  <label>I will recieve</label>
                                                  <div className="input_row_q">
                                                    <input type="text" />
                                                    <div>
                                                      <span>USDT</span>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form_p2p mb-4">
                                                  <label>Paymet Method</label>
                                                  <div className="selct_pay">
                                                    <Dropdown
                                                      placeholder="Select Friend"
                                                      fluid
                                                      selection
                                                      className="data_dropdoen"
                                                      options={friendOptions}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="button_cancel">
                                                  <Button>Cancel</Button>
                                                  <Button className="color_section">
                                                    Buy BTC
                                                  </Button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="profile_namepe">
                                        <span>J</span>
                                        <div className="details">
                                          <p>junawasarkar</p>
                                          <small>
                                            16 orders | 100.00% completion
                                          </small>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="price_">
                                        <p>
                                          80.50 <span>INR</span>
                                        </p>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="avli_limit">
                                        <div className="price_">
                                          <p>
                                            <span>Available</span> 675.47 USDT
                                          </p>
                                        </div>
                                        <div className="price_">
                                          <p>
                                            <span>Limit</span> ₹18,500.00 - ₹
                                            59,779.09
                                          </p>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="playment_methoid">
                                        Bank Transfer
                                      </div>
                                    </td>

                                    <td>
                                      <Button className="btn_buy">
                                        Buy BTC
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="profile_namepe">
                                        <span>J</span>
                                        <div className="details">
                                          <p>junawasarkar</p>
                                          <small>
                                            16 orders | 100.00% completion
                                          </small>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="price_">
                                        <p>
                                          80.50 <span>INR</span>
                                        </p>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="avli_limit">
                                        <div className="price_">
                                          <p>
                                            <span>Available</span> 675.47 USDT
                                          </p>
                                        </div>
                                        <div className="price_">
                                          <p>
                                            <span>Limit</span> ₹18,500.00 - ₹
                                            59,779.09
                                          </p>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="playment_methoid">
                                        Bank Transfer
                                      </div>
                                    </td>

                                    <td>
                                      <Button className="btn_buy">
                                        Buy BTC
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="profile_namepe">
                                        <span>J</span>
                                        <div className="details">
                                          <p>junawasarkar</p>
                                          <small>
                                            16 orders | 100.00% completion
                                          </small>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="price_">
                                        <p>
                                          80.50 <span>INR</span>
                                        </p>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="avli_limit">
                                        <div className="price_">
                                          <p>
                                            <span>Available</span> 675.47 USDT
                                          </p>
                                        </div>
                                        <div className="price_">
                                          <p>
                                            <span>Limit</span> ₹18,500.00 - ₹
                                            59,779.09
                                          </p>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="playment_methoid">
                                        Bank Transfer
                                      </div>
                                    </td>

                                    <td>
                                      <Button className="btn_buy">
                                        Buy BTC
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="profile_namepe">
                                        <span>J</span>
                                        <div className="details">
                                          <p>junawasarkar</p>
                                          <small>
                                            16 orders | 100.00% completion
                                          </small>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="price_">
                                        <p>
                                          80.50 <span>INR</span>
                                        </p>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="avli_limit">
                                        <div className="price_">
                                          <p>
                                            <span>Available</span> 675.47 USDT
                                          </p>
                                        </div>
                                        <div className="price_">
                                          <p>
                                            <span>Limit</span> ₹18,500.00 - ₹
                                            59,779.09
                                          </p>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="playment_methoid">
                                        Bank Transfer
                                      </div>
                                    </td>

                                    <td>
                                      <Button className="btn_buy">
                                        Buy BTC
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="profile_namepe">
                                        <span>J</span>
                                        <div className="details">
                                          <p>junawasarkar</p>
                                          <small>
                                            16 orders | 100.00% completion
                                          </small>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="price_">
                                        <p>
                                          80.50 <span>INR</span>
                                        </p>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="avli_limit">
                                        <div className="price_">
                                          <p>
                                            <span>Available</span> 675.47 USDT
                                          </p>
                                        </div>
                                        <div className="price_">
                                          <p>
                                            <span>Limit</span> ₹18,500.00 - ₹
                                            59,779.09
                                          </p>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="playment_methoid">
                                        Bank Transfer
                                      </div>
                                    </td>

                                    <td>
                                      <Button className="btn_buy">
                                        Buy BTC
                                      </Button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div id="USDT" class="tab-pane fade">
                          <h3>Menu 1</h3>
                          <p>Some content in menu 1.</p>
                        </div>
                        <div id="ETH" class="tab-pane fade">
                          <h3>Menu 2</h3>
                          <p>Some content in menu 2.</p>
                        </div>
                      </div>
                    </div>
                    <div id="sell" class="tab-pane fade"></div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
