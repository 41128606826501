import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "semantic-ui-react";
import Footer from "./Footer";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import Moment from "moment";
import Pagination from "react-js-pagination";
import { Link, useNavigate } from "react-router-dom";

function Home() {

  const navigate = useNavigate();
  const back = () => {
    navigate('/p2p')
  }

  const [p2pOrders, setp2pOrders] = useState([]);
  const [p2pcurrentpage, setp2pcurrentpage] = useState(1)
  const [p2ptotalpage, setp2pTotalpages] = useState(0);
  const [loader, setLoader] = useState(false);

  const getp2pOrders = async (page) => {
    var data = {
      apiUrl: apiService.p2pHistory,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var p2p_orders_list = await postMethod(data);
    console.log("p2p_orders_list===", p2p_orders_list.returnObj.Message);
    setLoader(true);
    if (p2p_orders_list.status) {
      setLoader(false);
      setp2pOrders(p2p_orders_list.returnObj.Message);
      setp2pTotalpages(p2p_orders_list.returnObj.total);
    }
  }

  const p2precordpage = 5;
  const p2ppagerange = 5;
  const handlepagep2p = (p2ppage) => {
    console.log(p2ppage, "==-=-=-p2ppage==-==-=")
    getp2pOrders(p2ppage);
    setp2pcurrentpage(p2ppage)
  }

  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    getp2pOrders(1);
  }, [0]);

  const navchatpage = async (link) => {
    navigate("/p2p/chat/" + link);
  };


  return (
    <div className="header">
      <Header />
      <main className="main_heinght">
        {loader == true ? (
          <div class="spinner css-ezm1fk">
            <div class="css-1qoumk2"></div>
            <div class="css-1v19680"></div>
            <div class="css-1v90jie"></div>
            <div class="css-19p0rvp"></div>
          </div>
        ) : (
          <>
            <section className="deposut_section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="headin_deposur_s">
                      <h3>
                        <Link to="/dashboard">
                          <i class="ri-arrow-left-fill"></i>
                        </Link>
                        Recent P2P History
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="tavle_transactio">
              <div className="padding_section">
                <div className="container">
                  <div className="buttonCls">
                    <h4 className="heading_class_recent p-3">My History</h4>
                    <Button
                      className="swap_but1" onClick={back}>Back</Button>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="market_tabel w-100 mt-0 p-3">
                        <div className="market_section shadow border-rounded p-4" >
                          <div className="table-responsive">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>Date & Time</th>
                                  <th>Currency</th>
                                  <th>Quantity</th>
                                  <th>Price</th>
                                  <th>Order Type</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {p2pOrders && p2pOrders.length > 0 ? (
                                  p2pOrders.map((item, i) => {
                                    var status = "";
                                    if (item.status == 0) {
                                      status = "Confirmed";
                                    }
                                    if (item.status == 1) {
                                      status = "Paid";
                                    }
                                    else if (item.status == 2) {
                                      status = "Completed";
                                    }
                                    else if (item.status == 3) {
                                      status = "Cancelled";
                                    }
                                    return (
                                      <tr onClick={() => navchatpage(item.orderId)} className="cursor_pointer">
                                        <td>
                                          <p> {Moment(item.datetime).format("lll")}</p>
                                        </td>
                                        <td>
                                          <p>
                                            {item.fromCurrency}
                                          </p>
                                        </td>
                                        <td>
                                          <p>{parseFloat(item.askAmount).toFixed(8)}</p>
                                        </td>
                                        <td>
                                          <p>{item.askPrice}</p>
                                        </td>

                                        <td>
                                          <p className={item.orderType == "buy" ? "text-green" : "text-red"}>{item.type == "buy" ? "Buy" : "Sell"}</p>
                                        </td>

                                        <td>
                                          <p>{status}</p>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="6">
                                      <p className="text-center">No records found</p>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <Pagination
                              itemClass="page-item" // add it for bootstrap 4
                              linkClass="page-link" // add it for bootstrap 4
                              activePage={p2pcurrentpage}
                              itemsCountPerPage={p2precordpage}
                              totalItemsCount={p2ptotalpage}
                              pageRangeDisplayed={p2ppagerange}
                              onChange={handlepagep2p}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </main>
      <Footer />
    </div>
  );
}

export default Home;
