import React, {useEffect} from "react";
import Header from "./Header";
import {Button} from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import {Link} from "react-router-dom";
import Sidebar from "./Sidebarlink";
import {toast} from "react-toastify";
import {env} from "../core/service/envconfig";
import useState from "react-usestateref";
import apiService from "../core/service/detail";
import {postMethod, getMethod} from "../core/service/common.api";
function Home() {
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  useEffect(() => {
    getkyc();
  }, [0]);

  const initialFormValue = {
    address_proof: "",
    id_proof: "",
    photo_proof: "",
    pan_number: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);
  const [proof1_status, setproof1_status] = useState(0);
  const [proof2_status, setproof2_status] = useState(0);
  const [proof3_status, setproof3_status] = useState(0);
  const [addressProof, setaddressProof, addressProofref] = useState(false);
  const [idProof, setidProof, idProofref] = useState(false);
  const [photoProof, setphotoProof, photoProofref] = useState(false);
  const [panNumber, setpanNumber, panNumberref] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [address_doc, setaddress_doc] = useState("");
  const [id_doc, setid_doc] = useState("");
  const [photo_doc, setphoto_doc] = useState("");
  const [loading, setloading] = useState(false);
  const [kycstatus, setkycstatus] = useState(0);
  const [pan_Number, setPanNumber] = useState("");
  const [loader, Setloader] = useState(false);

  const {address_proof, id_proof, photo_proof, pan_number} = formValue;

  const handleChange = async (e) => {
    console.log("e====", e);
    e.preventDefault();
    const {name, value} = e.target;
    let formData = {...formValue, ...{[name]: value}};
    console.log("formData====", formData);
    setFormValue(formData);
  };

  const imageUpload = (type, val) => {
    try {
      console.log(type, "type");
      console.log(val, "val");
      const fileExtension = val.name.split(".").at(-1);
      const fileSize = val.size;
      const fileName = val.name;
      if (
        fileExtension != "png" &&
        fileExtension != "jpg" &&
        fileExtension != "jpeg"
      ) {
        toast.error(
          "File does not support. You must use .png or .jpg or .jpeg "
        );
        return false;
      } else if (fileSize > 1000000) {
        toast.error("Please upload a file smaller than 1 MB");
        return false;
      } else {
        const data = new FormData();
        data.append("file", val);
        data.append("upload_preset", env.preset);
        data.append("cloud_name", env.cloud_name);
        console.log("formdata===", data);
        fetch(
          "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
          {
            method: "post",
            body: data,
          }
        )
          .then((resp) => resp.json())
          .then((data) => {
            console.log("cloudinary upload===", data.secure_url);
            if (type == "address_proof") {
              setaddressProof(false);
              setaddress_doc(data.secure_url);
            } else if (type == "id_proof") {
              setidProof(false);
              setid_doc(data.secure_url);
            } else if (type == "photo_proof") {
              setphotoProof(false);
              setphoto_doc(data.secure_url);
            }
          });
      }
    } catch (error) {}
  };
  const validate = async (values) => {
    const errors = {};
    if (values.pan_number == "") {
      errors.pan_number = "Pan Card Details is required!";
      setpanNumber(true);
    }
    else if (values.pan_number.length < 4 || values.pan_number.length > 15) {
      errors.pan_number = "Pan Card number should be 4 to 15 characters long";
      setpanNumber(true);
    }
    else
    {
      errors.pan_number = "";
      setpanNumber(false);
    }
    if (address_doc == "") {
      errors.address_proof = "Address Proof is required!";
      setaddressProof(true);
    }

    if (id_doc == "") {
      console.log("call here-----");
      errors.id_proof = "ID Proof is required!";
      console.log("validationnErr==", validationnErr);
      setidProof(true);
    }

    if (photo_doc == "") {
      errors.photo_proof = "Photo proof is required!";
      setphotoProof(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const getkyc = async () => {
    try {
      Setloader(true);
      var data = {
        apiUrl: apiService.getKYC,
      };
      var resp = await getMethod(data);
      //console.log(resp, "resp");
      if(resp.status)
      {
        Setloader(false);
        setkycstatus(resp.datas.kycDetails.kycStatus);
        setaddress_doc(resp.datas.kycDetails.proof1);
        setid_doc(resp.datas.kycDetails.proof2);
        setphoto_doc(resp.datas.kycDetails.proof3);
        setproof1_status(resp.datas.kycDetails.prrof1status);
        setproof2_status(resp.datas.kycDetails.prrof2status);
        setproof3_status(resp.datas.kycDetails.prrof3status);
        setPanNumber(resp.datas.kycDetails.pan);
      }
      // formValue["pan_number"] = resp.datas.kycDetails.pan;
      // console.log(resp.datas.kycDetails.pan)
      // console.log(formValue.pan_number)
    } catch {}
  };
  const submit = async () => {
    try {
      validate(formValue);
      formValue.address_proof = address_doc;
      formValue.id_proof = id_doc;
      formValue.photo_proof = photo_doc;
      formValue.pan_number = pan_number;
      if (
        formValue.address_proof != "" &&
        formValue.id_proof != "" &&
        formValue.photo_proof != "" &&
        formValue.pan_number != ""
      ) {
        var data = {
          apiUrl: apiService.savekyc_id,
          payload: formValue,
        };
        var resp = await postMethod(data);
        if (resp.status) {
          setloading(false);
          getkyc();
          toast.success(resp.Message);
        } else {
          toast.error(resp.Message);
        }
      } else {
        toast.error("Please fill all the required fields");
        console.log("ALL FIELD NEED");
      }
    } catch (error) {}
  };
  return (
    <div className="header ">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
          {
              loader == true ? 
              <div class="spinner css-ezm1fk">
              <div class="css-1qoumk2"></div>
              <div class="css-1v19680"></div>
              <div class="css-1v90jie"></div>
              <div class="css-19p0rvp"></div>
            </div> :
            <div className="section_bg_ash">
              <div className="section_selec_ash">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12">
                      <h2 className="heading_titlr">KYC verification</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="inner_conteten">
                <div className="contant_secion_balance mb-5">
                  <h3 className="heading_sub_section">Personal verification</h3>

                  <p className="mt-0 paragraph">
                    Upload an identity document (Passport, Voters ID, Aadhar
                    Card). and a copy of your PAN card.
                    <br />
                    When cropping your document, please ensure the 4 edges are
                    clearly visible.
                  </p>
                  <p className="para-class">
                  KYC status:{" "}
                  {kycstatus == 0 ? (
                    <span className=" status_KYC status_done text-red">
                      Not Uploaded
                    </span>
                  ) : (
                    ""
                  )}
                  {kycstatus == 1 ? (
                    <span className="status_KYC status_done text-green">
                      Verified
                    </span>
                  ) : (
                    ""
                  )}
                  {kycstatus == 2 ? (
                    <span className="status_KYC status_pending text-yellow">
                      Pending
                    </span>
                  ) : (
                    ""
                  )}
                  {kycstatus == 3 ? (
                    <span className="status_KYC status_rejected text-red">
                      Rejected
                    </span>
                  ) : (
                    ""
                  )}
                </p>
                </div>
             
                <div className="row">
                  <div className="col-lg-4">
                    <h3 className="input_labele">Address Proof</h3>
                    <div className="card_kyc">
                      <span>
                        {address_doc == "" ? (
                          <i class="ri-article-fill"></i>
                        ) : (
                          <img
                            src={address_doc}
                            className="input_section_kyc"
                          />
                        )}
                      </span>
                      {kycstatus == 0 || kycstatus == 3 ? (

                      <div className="input_file">
                        <input
                          type="file" 
                          name="image"
                          onChange={(e) =>
                            imageUpload(
                              "address_proof",
                              e.target.files[0]
                            )
                          }/>
                        <p>
                          <i class="ri-upload-2-line"></i> Upload File
                        </p>
                        
                      </div>
                      ):("")}
 
                      {addressProofref.current == true ? (
                        <p className="text-danger text-center">
                          {" "}
                          <i class="bi bi-exclamation-diamond"></i>
                          {validationnErr.address_proof}{" "}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <h3 className="input_labele">ID Proof</h3>
                    <div className="card_kyc">
                      <span>
                      {id_doc == "" ? (
                        <i class="ri-article-fill"></i>
                            ) : (
                              <img src={id_doc} className="" />
                            )}
                        </span>
                        {kycstatus == 0 ||kycstatus == 3 ? (
                      <div className="input_file">
                        <input 
                        type="file" 
                        name="image"
                        onChange={(e) =>
                          imageUpload(
                            "id_proof",
                            e.target.files[0]
                          )
                        }/>
                        <p>
                          <i class="ri-upload-2-line"></i> Upload File
                        </p>
                      </div>
                                  ):("")}
                      {idProofref.current == true ? (
                        <p className="text-danger warningicon text-center">
                          {" "}
                          <i class="bi bi-exclamation-diamond"></i>
                          {validationnErr.id_proof}{" "}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <h3 className="input_labele">Photo Upload</h3>
                    <div className="card_kyc">
                      <span>
                        {photo_doc == "" ? (
                          <i class="ri-article-fill"></i>
                        ) : (
                          <img src={photo_doc} className="" />
                        )}
                      </span>
                      {kycstatus == 0 || kycstatus == 3  ? (

                      <div className="input_file">
                        <input type="file" 
                         name="image"
                                onChange={(e) =>
                                  imageUpload("photo_proof", e.target.files[0])
                                }/>
                        <p>
                          <i class="ri-upload-2-line"></i> Upload File
                        </p>
                      </div>
                        ):("")}
                      {photoProofref.current == true ? (
                        <p className="text-danger text-center">
                          {" "}
                          <i class="bi bi-exclamation-diamond"></i>
                          {validationnErr.photo_proof}{" "}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="col-lg-12">
                  {kycstatus == 0 || kycstatus == 3 ? (
                    <div className="col-lg-4">
                      <h3 className="input_labele">PAN Card details</h3>
                      <input
                        type="text"
                        placeholder="Enter PAN Card details"
                        className="pan_number"
                        name="pan_number"
                        value={pan_number}
                        onChange={handleChange}
                        minLength={4}
                        maxLength={15}
                      />
                    </div>
                  ) : (
                    <p className="d-flex justify-content-between align-items-center w-100 mb-1">
                      Pan Card Details :{pan_Number}
                    </p>
                  )}
                  </div>
                 
                </div>
                {panNumberref.current == true ? (
                                      <p className="text-danger">
                                        {" "}
                                        {validationnErr.pan_number}{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                        {kycstatus == 0 || kycstatus == 3 ? (
                <div className="market_tabel">
                     {loading == true ? (
                                              <Button class="button-red security__button button_www button_margud">
                                                Loading
                                              </Button>
                                            ) : (
                                              <Button
                                                class="button-red security__button button_www button_margud"
                                                onClick={submit}
                                              >
                                                Submit
                                              </Button>
                                            )}
                  {/* <Button onClick={submit}>Submit</Button> */}
                </div>
                                      ):("")}

              </div>
            </div>
          }
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
