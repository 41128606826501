import React, {useState, useEffect} from "react";
import Header from "./Header";
import {Button} from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import {Link, useNavigate} from "react-router-dom";
import Sidebar from "./Sidebarlink";
import apiService from "../core/service/detail";
import {postMethod, getMethod} from "../core/service/common.api";
import moment from "moment";
import Pagination from "react-js-pagination";

function Home() {
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];

  const navigate = useNavigate();
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const [getUserId, setGetUserId] = useState("");
  const [getUserlostlogintime, setGetUserlostlogintime] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [total, settotal] = useState(0);
  const [perpage, setperpage] = useState(5);
  const [getUserBalance, setGetUserBalance] = useState([]);
  const [change, setChange] = useState([]);
  const [btcbalance, setBtcBalance] = useState();
  const [usdtbalance, setUsdtBalance] = useState();
  const [userDetails, setuserDetails] = useState("");
  const [reserverbalance, setReserveBalance] = useState(0);

  const [loader, Setloader] = useState(false);

  useEffect(() => {
    var token = localStorage.getItem("user_token");
    console.log(token, "=-=-=-=-=-=-=-=--=-=-");
    if (token != null && token != undefined) {
      console.log("call here===");
      getUserDetails();
      getuserbalance(currentPage);
      getbalance();
    } else if (token == undefined) {
      console.log("call 1231here===");
      localStorage.clear();
      navigate("/login");
    } else {
    }
  }, [0]);

  var getbalance = async () => {
    Setloader(true);
    var data = {
      apiUrl: apiService.get_Btc_Usdt_Balance,
    };
    var resp = await getMethod(data);
    if (resp.status == true) {
      Setloader(false);
      console.log(resp, "respgeiurtbtaclal");
      console.log(resp.balance.btc_balance, "respgeiurtbtaclal");
      console.log(resp.balance.total_balance_usdt, "respgeiurtbtaclal");
      setBtcBalance(resp.balance.btc_balance);
      setUsdtBalance(resp.balance.total_balance_usdt);
      setReserveBalance(resp.balance.total_reserve);
    }
  };
  var getUserDetails = async () => {
    var data = {
      apiUrl: apiService.getUserDetails,
    };
    var resp = await getMethod(data);
    if (resp.status == true) {
      console.log(resp, "resp");
      setGetUserId(resp.login_response.user_id);
      setuserDetails(resp.data);
      var time = moment(resp.login_response.createddate).format("lll");
      setGetUserlostlogintime(time);
    }
  };
  const recordPerPage = 5;
  const pageRange = 5;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getuserbalance(pageNumber);
  };
  var getuserbalance = async (pages) => {
    var obj = {
      perpage: perpage,
      page: pages,
    };
    console.log(obj, "obj");
    var data = {
      apiUrl: apiService.getUserBalance,
      payload: obj,
    };
    var resp = await postMethod(data);
    console.log(resp, "respresp");
    setGetUserBalance(resp.Message);
    settotal(resp.total);
    setChange(resp.changes);
  };
  return (
    <div className="header ">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            {loader == true ? (
              <div class="spinner css-ezm1fk">
                <div class="css-1qoumk2"></div>
                <div class="css-1v19680"></div>
                <div class="css-1v90jie"></div>
                <div class="css-19p0rvp"></div>
              </div>
            ) : (
              <div className="section_bg_ash">
                <div className="section_selec_ash">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-1 d-flex align-items-center">
                        <div className="profile">
                          <i class="ri-user-3-fill"></i>
                        </div>
                      </div>
                      <div className="col-lg-11">
                        <div className="profile_name">
                          <h3>
                            {userDetails.username}{" "}
                            {/* <Link to="/editprofile">
                              <i class="ri-edit-line"></i>
                            </Link> */}
                            {/* <Link to="">
                              <i class="ri-edit-line"></i>
                            </Link> */}
                          </h3>
                        </div>
                        <div className="activeiryt_content css-trn0qc ">
                          <div className="css-1osdvxh">
                            <div className="css-chqiix">
                              <span className="css-mx5ldy">User ID</span>
                            </div>
                            <div className="css-7nipyh">
                              <span className="css-7nipyh">
                                {getUserId.substring(14)}
                              </span>
                            </div>
                          </div>
                          {/* <div className="css-1osdvxh">
                        <div className="css-chqiix">
                          <span className="css-mx5ldy">User Type</span>
                        </div>
                        <div className="css-7nipyh">
                          <span className="css-7nipyh">Regular User</span>
                        </div>
                      </div> */}
                          <div className="css-1osdvxh">
                            <div className="css-chqiix">
                              <span className="css-mx5ldy">
                                Last login time
                              </span>
                            </div>
                            <div className="css-7nipyh">
                              <span className="css-7nipyh">
                                {getUserlostlogintime}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inner_conteten">
                  <div className="contant_secion_balance shadow rounded p-4">
                    <h4>Estimated Balance</h4>
                    {/* // <h2>
                    //   <span>
                    //     {parseFloat(btcbalance && btcbalance).toFixed(7)}{" "}
                    //     BTC
                    //   </span>{" "}
                    //   ≈ ${parseFloat(usdtbalance && usdtbalance).toFixed(4)}
                      
                    // </h2> */}

                    <h2>
                      <span>
                        {parseFloat(usdtbalance && usdtbalance).toFixed(4)} USDT
                      </span>{" "}
                    </h2>
                    <p>
                      Your account does not currently have any assets, complete
                      identity verification in order to make deposits to your
                      account.
                    </p>
                  </div>

                  {/* <div className="contant_secion_balance">
                    <h4>Reserve Balance</h4>
                    <h2>
                      <span>
                        {parseFloat(reserverbalance && reserverbalance).toFixed(7)}{" "}
                        ADVB(new)
                      </span>{" "}
                    </h2>
                    <p>
                     25 ADVB(new) deposited from reserve wallet to main wallet as per daily basis
                    </p>
                  </div> */}
                  <div className="market_tabel shadow p-4 w-100">
                    <div className="contant_secion_balance">
                      <h4>Market</h4>
                    </div>
                    <div className="market_section ">
                      <div className=" ">
                        <div className="table-responsive ">
                          <table class="table table-hover">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Amount</th>
                                <th> Price</th>
                                {/* <th>24h Change</th> */}
                                {/* <th className="text-right"></th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {getUserBalance &&
                                getUserBalance.map((item, i) => {
                                  return (
                                    <tr>
                                      <td>
                                        <div className="coin_section">
                                          <img
                                            src={item.currencyImage}
                                            className="blackshow"
                                          />
                                          <span>{item.currencysymbol}</span>{" "}
                                          <small>{item.currencyName}</small>
                                        </div>
                                      </td>
                                      <td>
                                        <p>
                                          <h5>
                                            {parseFloat(
                                              item.currencyBalance
                                            ).toFixed(8)}{" "}
                                            {/* {item.currencysymbol} */}
                                          </h5>
                                          {/* <span>
                                    {parseFloat(
                                      item.estimatedUSDTbalance
                                    ).toFixed(2)}{" "}
                                    ₹{" "}
                                  </span> */}
                                        </p>
                                        {/* <p>$43,975.72</p> */}
                                      </td>
                                      <td>
                                        <p> {item.EstimatedUSDT} </p>
                                      </td>
                                      {/* <td>
                            <p className="green_text">
                              +0./60% */}
                                      {/* {change && change.map((item,i)=>{
                              console.log(item,"item")
                              return(
                                <p>{item.findfirstpair}</p>,
                                <p>{item.findsecondpair}</p>,
                                <p>{item.findthirdpair}</p>,
                                <p>{item.findfourthpair}</p>,
                                <p>{item.findfifthpair}</p>
                            
                                // {item}
                                // "kkk"
                              )
                            })} */}
                                      {/* </p>
                          </td> */}
                                      {/* <td>
                                        <Link
                                          to={
                                            item.currencysymbol == "USDT"
                                              ? "/trade/ADVB_USDT"
                                              : "/trade/" +
                                                item.currencysymbol +
                                                "_USDT"
                                          }
                                          className="nav_link_trade"
                                        >
                                          Trade
                                        </Link>
                                      </td> */}
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={currentPage}
                      itemsCountPerPage={recordPerPage}
                      totalItemsCount={total}
                      pageRangeDisplayed={pageRange}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
