import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from "./Footer";
// import Bgvedio from "../Image/globe-fiat-block.mp4";
import BannerUI from "./Banner";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import moment from "moment";
import Pagination from "react-js-pagination";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  TelegramShareButton,
  InstapaperIcon,
  InstapaperShareButton,
} from "react-share";
import { Link } from "react-router-dom";
import Select from "react-select";
import Moment from "moment";
import Sidebar from "./Sidebarlink";

function Home() {
  const friendOptions = [
    {
      key: "BTC",
      text: "BTC",
      value: "BTC",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/jenny.jpg",
      },
    },
    {
      key: "ETH",
      text: "ETH",
      value: "ETH",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/elliot.jpg",
      },
    },
  ];
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [currency, setcurrency, currencyref] = useState("");
  const [cointype, setcointype, cointyperef] = useState("");
  const [address, setAddress, addressref] = useState("");
  const [view, setview, viewref] = useState("");
  const [bankwire, setBankwire] = useState("");
  const [depositHistory, setdepositHistory] = useState([]);
  const [kycStatus, setkycStatus] = useState(1);
  const [cur_currency, setcur_currency, cur_currencyref] = useState("");
  const [network_currency, setcur_network, network_currencyref] = useState([]);
  const [network_default, setnet_default, net_defaultref] = useState("");
  const [maintain, setmaintain, maintainref] = useState(false);
  const [loader, setloader] = useState(false);
  const [totalPage, setTotalpages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [adminBankdetails, setadminBankdetails] = useState({});
  const [transaction_proof, settransaction_proof] = useState("");
  const [transaction_docref, settransaction_doc, transaction_doc] =
    useState("");
  const [txnProofref, settxnProof, txnProof] = useState(false);
  const [fiatAmoutref, setfiatAmout, fiatAmout] = useState("");
  const [AmountValidref, setAmountValid, AmountValid] = useState(false);
  const [viewFiatdeposit, setviewFiatdeposit] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);

  const recordPerPage = 5;
  const pageRange = 5;

  useEffect(() => {
    getAllcurrency();
    getdepositHistory(1);
  }, [0]);

  const FiatDeposit = async () => {
    console.log(fiatAmout, "=-=-==", transaction_doc);
    if (fiatAmout.current == "") {
      setAmountValid(true);
    } else {
      setAmountValid(false);
    }
    if (transaction_doc.current == "") {
      settxnProof(true);
    } else {
      settxnProof(false);
    }
    if (fiatAmout.current != "" && transaction_doc.current != "") {
      settxnProof(false);
      setAmountValid(false);
      var obj = {
        amount: fiatAmout.current,
        currId: adminBankdetails.currency_id,
        currency_symbol: adminBankdetails.currency,
        txn_proof: transaction_doc.current,
      };

      var data = {
        apiUrl: apiService.fiatdepositSubmit,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status == true) {
        getdepositHistory(1);
        toast.success(resp.Message);
        setviewFiatdeposit(false);
      } else {
        toast.error(resp.Message);
      }
    }
  };
  const imageUpload = (type, val) => {
    console.log("type===", type);
    console.log("val===", val);
    const fileExtension = val.name.split(".").at(-1);
    const fileSize = val.size;
    const fileName = val.name;
    console.log("fileExtension===", fileExtension);
    console.log("fileSize===", fileSize);
    console.log("fileName===", fileName);
    if (
      fileExtension != "png" &&
      fileExtension != "jpg" &&
      fileExtension != "jpeg" &&
      fileExtension != "docx" &&
      fileExtension != "pdf"
    ) {
      settransaction_proof("");
      toast.error("File does not support. You must use .png or .jpg or .jpeg ");
      return false;
    } else if (fileSize > 1000000) {
      settransaction_proof("");
      toast.error("Please upload a file smaller than 1 MB");
      return false;
    } else {
      const data = new FormData();
      settransaction_proof(data.append("file", val));
      data.append("file", val);
      data.append("upload_preset", "sztbiwly");
      data.append("cloud_name", "taikonz-com");
      console.log("formdata===", data);
      fetch("https://api.cloudinary.com/v1_1/taikonz-com/auto/upload", {
        method: "post",
        body: data,
      })
        .then((resp) => resp.json())
        .then((data) => {
          console.log("cloudinary upload===", data);
          if (type == "txn_proof") {
            settransaction_doc(data.secure_url);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const getAllcurrency = async () => {
    setloader(true);
    var data = {
      apiUrl: apiService.depositcurrency,
    };
    var resp = await getMethod(data);
    if (resp) {
      setloader(false);
      var currArrayCrypto = [];
      var data = resp.data;
      setallCrypto(data);
      for (var i = 0; i < data.length; i++) {
        var obj = {
          value: data[i]._id,
          //label: data[i].currencySymbol,
          key: data[i].currencySymbol,
          text: data[i].currencySymbol,
          image: {
            avatar: true,
            src: data[i].Currency_image,
          },
          coinType: data[i].coinType,
          erc20token: data[i].erc20token,
          bep20token: data[i].bep20token,
          trc20token: data[i].trc20token,
          rptc20token: data[i].rptc20token,
        };
        currArrayCrypto.push(obj);
      }
      setallCurrency(currArrayCrypto);
    }
  };

  const copy = (content,message) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(content);
      toast.success(message+" copied successfully");
    } else {
      toast.success("Link not copied, please try after sometimes!");
    }
  };

  const onSelect = async (e, option) => {
    console.log(option, "-=-onSelecttop");
    // if(option.label == "RPTC" || option.label == "RWT")
    // {
    //    setmaintain(true);
    // }
    // else
    // {
    //   setmaintain(false);

    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );
    if (indexData != -1) {
      var currencydata = allCryptoref.current[indexData];
      if (currencydata.coinType == "2") {
        var obj = {
          currency: currencydata.currencySymbol,
        };
        var data = {
          apiUrl: apiService.getAdminBank,
          payload: obj,
        };
        var resp = await postMethod(data);
        if (resp.status == true) {
          setadminBankdetails(resp.data);
          console.log(cur_currencyref.current, "=-=-=cur_currencyref.current");
          setview("load");
          setviewFiatdeposit(true);
        }
      } else {
        setviewFiatdeposit(false);
      }
      console.log("currencydata===", currencydata);
      setcur_currency(currencydata);
      setcur_network([]);
      setnet_default("");
      var network_cur = {};
      var network_names = [];
      if (currencydata.currencyType == "2") {
        if (currencydata.erc20token == "1") {
          network_cur = {
            value: "erc20token",
            label: "ERC20",
          };
          network_names.push(network_cur);
        }
        if (currencydata.bep20token == "1") {
          network_cur = {
            value: "bep20token",
            label: "BEP20",
          };
          network_names.push(network_cur);
        }
        if (currencydata.trc20token == "1") {
          network_cur = {
            value: "trc20token",
            label: "TRC20",
          };
          network_names.push(network_cur);
        }

        if (currencydata.rptc20token == "1") {
          network_cur = {
            value: "rptc20token",
            label: "RPTC20",
          };
          network_names.push(network_cur);
        }
        setcur_network(network_names);
        console.log("network_currencyref===", network_currencyref.current);
        setnet_default(network_currencyref.current[0].label);
        setcurrency(currencydata);
        setcointype(currencydata.coinType);
      } else {
        console.log("=-=-=-=-INR");
      }

      if (currencydata.coinType == "1" && currencydata.currencyType == "1") {
        var obj = {
          currency: currencydata.currencySymbol,
          currId: currencydata._id,
        };
        var data = {
          apiUrl: apiService.generateAddress,
          payload: obj,
        };
        setview("load");
        var resp = await postMethod(data);
        //console.log(resp, "=-=-=resp-=-=--");
        if (resp.status) {
          setview("view");
          console.log("viewref====", viewref.current);
          setAddress(resp.Message);
        } else {
        }
      } else {
        var obj = {
          currency: currencydata.currencySymbol,
        };
        var data = {
          apiUrl: apiService.bankwire,
          payload: obj,
        };

        var resp = await postMethod(data);
        console.log(resp, "=-=-=fiat deposit resp-=-=--");
        if (resp.status) {
          setBankwire(resp.data);
        } else {
          //toast.error("Something went wrong, please try again later");
        }
      }
    }
  };

  const onSelect_network = async (option) => {
    console.log(option, "-=-onSelect_network");
    if (
      cur_currencyref.current.coinType == "1" &&
      cur_currencyref.current.currencyType == "2"
    ) {
      var obj = {
        currency: cur_currencyref.current.currencySymbol,
        currId: cur_currencyref.current._id,
        network: option.value,
      };
      console.log("call here 1111", obj);
      var data = {
        apiUrl: apiService.generateAddress_network,
        payload: obj,
      };
      setview("load");
      var resp = await postMethod(data);
      console.log(resp, "=-=-=resp-=-=--");
      if (resp.status) {
        setview("view");
        setAddress(resp.Message);
        // if (depData.erc20token == "1") {
        //   setNetwork("ERC20");
        // } else if (depData.trc20token == "1") {
        //   setNetwork("TRC20");
        // } else if (depData.bep20token == "1") {
        //   setNetwork("BEP20");
        // }
      } else {
        //toast.error("Something went wrong, please try again later");
      }
    }
  };

  const getdepositHistory = async (page) => {
    var obj = {
      apiUrl: apiService.deposit_history,
      payload: { FilPerpage: 10, FilPage: page },
    };
    var deposit_history_list = await postMethod(obj);
    if (deposit_history_list) {
      setdepositHistory(deposit_history_list.result);
      setTotalpages(deposit_history_list.pages);
    }
  };

  const handlePageChange = (pageNumber) => {
    getdepositHistory(pageNumber);
    setCurrentPage(pageNumber);
  };

  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            <main className="main_heinght">
              {loader == true ? (
                <div class="spinner css-ezm1fk">
                  <div class="css-1qoumk2"></div>
                  <div class="css-1v19680"></div>
                  <div class="css-1v90jie"></div>
                  <div class="css-19p0rvp"></div>
                </div>
              ) : (
                <>
                  <section className="deposut_section">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="headin_deposur_s">
                            <h3>
                              {/* <Link to="/dashboard">
                          <i class="ri-arrow-left-fill"></i>
                        </Link> */}
                              Deposit
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="form-deposit">
                    <div className="container-fluid">
                      <div className="row justify-content-center">
                        <div className="col-lg-6 border shadow rounded p-5">
                          <div className="crypto_coin_selec">
                            <label>Select Coin</label>
                            <Dropdown
                              placeholder="Select Coin"
                              fluid
                              selection
                              options={allCurrencyref.current}
                              onChange={onSelect}
                            />
                          </div>{" "}
                          {viewFiatdeposit == true ? (
                            <>
                              <div className="col-lg-12">
                                <h3>
                                  Deposit funds to your INR wallet via wire
                                  transfer
                                </h3>
                                <div className="col-lg-12 d-flex flex-row px-0">
                                  <div className="col-lg-6 pl-0">
                                    <div className="crypto_coin_selec px-0">
                                      <label>Beneficiary name</label>
                                      <div className="flex_coin_copy">
                                        <input
                                          type="text"
                                          disabled
                                          class="w-100"
                                          value={adminBankdetails.Name}
                                        />{" "}
                                      </div>
                                    </div>
                                    <div className="crypto_coin_selec px-0">
                                      <label>Account number</label>
                                      <div className="flex_coin_copy">
                                        <input
                                          type="text"
                                          disabled
                                          class="w-100"
                                          value={
                                            adminBankdetails.Account_Number
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="crypto_coin_selec px-0">
                                      <label>Account Type</label>
                                      <div className="flex_coin_copy">
                                        <input
                                          type="text"
                                          disabled
                                          class="w-100"
                                          value={adminBankdetails.Account_type}
                                        />
                                      </div>
                                    </div>
                                    <div className="crypto_coin_selec px-0">
                                      <label>Amount</label>
                                      <div className="flex_coin_copy">
                                        <input
                                          type="number"
                                          placeholder="Enter Amount"
                                          onChange={(e) =>
                                            setfiatAmout(e.target.value)
                                          }
                                          name="Amount"
                                        />
                                      </div>
                                      {AmountValid.current == true ? (
                                        <p style={{ color: "red" }}>
                                          Amount is required
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 pr-0">
                                    <div className="crypto_coin_selec px-0">
                                      <label>Bank Name</label>
                                      <div className="flex_coin_copy">
                                        <input
                                          type="text"
                                          disabled
                                          class="w-100"
                                          value={adminBankdetails.Bank_Name}
                                        />{" "}
                                      </div>
                                    </div>
                                    <div className="crypto_coin_selec px-0">
                                      <label>IFSC code</label>
                                      <div className="flex_coin_copy">
                                        <input
                                          type="text"
                                          disabled
                                          class="w-100"
                                          value={adminBankdetails.IFSC_code}
                                        />{" "}
                                      </div>
                                    </div>
                                    <div className="crypto_coin_selec px-0">
                                      <label>Branch Name</label>
                                      <div className="flex_coin_copy">
                                        <input
                                          type="text"
                                          disabled
                                          class="w-100"
                                          value={adminBankdetails.Branch_Name}
                                        />{" "}
                                      </div>
                                    </div>
                                    <div className="crypto_coin_selec px-0">
                                      <label>Transaction Proof</label>
                                      <div className="flex_coin_copy">
                                        <input
                                          type="file"
                                          name="Proof"
                                          onChange={(e) =>
                                            imageUpload(
                                              "txn_proof",
                                              e.target.files[0]
                                            )
                                          }
                                        />
                                      </div>
                                      {txnProof.current == true ? (
                                        <p style={{ color: "red" }}>
                                          Proof is required
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div class="form_login_input d-flex flex-column align-items-center">
                                  {buttonLoader == true ? (
                                    <Button className="button_ik">
                                      Loading...
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={FiatDeposit}
                                      className="button_ik"
                                    >
                                      Submit
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {cur_currencyref.current.currencyType == "2" ? (
                            <div className="crypto_coin_selec">
                              <label>Network</label>
                              <Select
                                options={network_currencyref.current}
                                placeholder="Select an Network"
                                onChange={(o) => onSelect_network(o)}
                                defaultValue={net_defaultref.current}
                                isSearchable={true}
                                className="call_seclecy"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {viewref.current == "view" ? (
                            <>
                              <div className="qr_section_class">
                                <h4>Deposit Address</h4>

                                {addressref.current != "" ? (
                                  <div className="qr_image_and_address">
                                    <div className="img_padding">
                                      <img
                                        src={addressref.current.qrcode}
                                        className="blackshow"
                                      />
                                    </div>
                                    <div className="form_address-copy">
                                      <div className="addresssss">
                                        <small>Address</small>
                                        <p>{addressref.current.address}</p>
                                      </div>
                                      <div className="icon">
                                        <i
                                          class="ri-file-copy-line"
                                          onClick={() =>
                                            copy(addressref.current.address,"Address")
                                          }
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {/* <div className="qr_section_class">
                      <h4>Deposit Address</h4>
                      <div className="qr_image_and_address">
                        <div className="img_padding">
                          <img
                            src={require("../Image/Vector99.png")}
                            className="blackshow"
                          />
                        </div>
                        <div className="form_address-copy">
                          <div className="addresssss">
                            <small>Address</small>
                            <p>
                              jhgsduyfg37826534kjbjb87247623jh476527347t7665
                            </p>
                          </div>
                          <div className="icon">
                            <i class="ri-file-copy-line"></i>
                          </div>
                        </div>
                      </div>
                    </div> */}
                          {viewref.current == "view" ? (
                            <div className="minimu_depost">
                              {/* <p>
                        Minimum deposit <span>0.000001BTC</span>
                      </p> */}
                              {/* <p>
                        Minimum Deposit <span>{cur_currencyref.current.minDepositLimit} </span>
                      </p>  */}
                              {/* {cur_currencyref.current.currencySymbol == "ADVB" ? (
                      <p>
                        Maximum Deposit <span>{cur_currencyref.current.maxDepositLimit} {cur_currencyref.current.currencySymbol} </span>
                      </p>
                      ) : ("")} */}

                              {addressref.current != "" ? (
                                <p>
                                  Available Balance{" "}
                                  <span>
                                    {" "}
                                    {addressref.current.balance}{" "}
                                    {cur_currencyref.current.currencySymbol}
                                  </span>
                                </p>
                              ) : (
                                ""
                              )}
                              {/* {cur_currencyref.current.currencySymbol == "ADVB" ? (
                      <p className="text-justify">
                      Note: If you deposit more than {cur_currencyref.current.maxDepositLimit} {cur_currencyref.current.currencySymbol} in 24hr . Then fund will be lost and Adverbex Exchange not responsible for that.
                      </p>
                      ) : ("")} */}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="tavle_transactio">
                    <div className="padding_section">
                      <div className="container-fluid">
                        <h4 className="heading_class_recent p-3">
                          Recent Deposits
                        </h4>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="market_tabel w-100 mt-0 p-3">
                              <div className="market_section">
                                <div className="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th>Name</th>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Address</th>
                                        <th>Status</th>
                                        <th className="text-right">TxID</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {depositHistory &&
                                      depositHistory.length > 0 ? (
                                        depositHistory.map((item, i) => {
                                          return (
                                            <tr>
                                              <td>
                                                <div className="coin_section">
                                                  <span>
                                                    {item.currencyName}
                                                  </span>{" "}
                                                  <small>
                                                    {item.currencySymbol}
                                                  </small>
                                                </div>
                                              </td>
                                              <td>
                                                <p>
                                                  {Moment(item.date).format(
                                                    "DD-MM-YYYY h:m a"
                                                  )}
                                                </p>
                                              </td>
                                              <td>
                                                <p>
                                                  {parseFloat(
                                                    item.amount
                                                  ).toFixed(4)}
                                                </p>
                                              </td>
                                              <td className="text_overflow">
                                              {item.address == "" ? (
                                                <p className="text-center">-</p>  
                                                ) : (
                                                <p>
                                                  {" "}
                                                  <i
                                                    class="ri-file-copy-line m-2"
                                                    onClick={() =>
                                                      copy(
                                                        item.address, "Address"
                                                      )
                                                    }
                                                  ></i>
                                                  {item.address.substring(
                                                    0,
                                                    15
                                                  )}...
                                                </p>)}
                                              </td>
                                              <td className="text_overflow">
                                                <p>
                                                  {" "}
                                                  {item.txnid == "Nil"
                                                    ? "Pending"
                                                    : "Approved"}
                                                </p>
                                              </td>
                                              <td className="text_overflow">
                                                {item.txnid == "" ? (
                                                  "---"
                                                ) : (
                                                  <p >
                                                    {" "}
                                                    <i
                                                      class="ri-file-copy-line m-2" 
                                                      onClick={() =>
                                                        copy(
                                                          item.txnid, "Transaction hash"
                                                        )
                                                      }
                                                    ></i>
                                                    {item.txnid.substring(
                                                      0,
                                                      15
                                                    )}...
                                                  </p>
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <tr>
                                          <td colSpan="5">
                                            <p className="text-center">
                                              No records found
                                            </p>
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            {depositHistory.length > 0 ? (
                              <Pagination
                                itemClass="page-item" // add it for bootstrap 4
                                linkClass="page-link" // add it for bootstrap 4
                                activePage={currentPage}
                                itemsCountPerPage={recordPerPage}
                                totalItemsCount={totalPage}
                                pageRangeDisplayed={pageRange}
                                onChange={handlePageChange}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              )}
            </main>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
