import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
function Home() {
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <div className="sidebar_link ">
      <div className="menuee">
        <NavLink to="/dashboard" className="css-z87e9z">
          <div className="css-10j588g ">
            {" "}
            <i class="ri-layout-grid-fill"></i>
            <div className="aherd_ineer">Dashboard</div>
          </div>
        </NavLink>
        <NavLink to="/kyc" className="css-z87e9z">
          <div className="css-10j588g ">
            {" "}
            <i class="ri-user-follow-fill"></i>
            <div className="aherd_ineer">KYC</div>
          </div>
        </NavLink>
        {/* <NavLink to="/merchant" className="css-z87e9z">
          <div className="css-10j588g ">
            {" "}
            <i class="ri-store-3-line"></i>
            <div className="aherd_ineer">Merchant</div>
          </div>
        </NavLink>
        <NavLink to="/widgets" className="css-z87e9z">
          <div className="css-10j588g ">
            {" "}
            <i class="ri-bank-card-2-line"></i>
            <div className="aherd_ineer">Widgets</div>
          </div>
        </NavLink> */}
        <NavLink to="/bankdetails" className="css-z87e9z">
          <div className="css-10j588g ">
            {" "}
            <i class="ri-secure-payment-line"></i>
            <div className="aherd_ineer">Payment Methods</div>
          </div>
        </NavLink>
        <NavLink to="/security" className="css-z87e9z">
          <div className="css-10j588g ">
            {" "}
            <i class="ri-lock-fill"></i>
            <div className="aherd_ineer">Security</div>
          </div>
        </NavLink>
        <NavLink to="/refferal" className="css-z87e9z">
          <div className="css-10j588g ">
            {" "}
            <i class="ri-user-add-fill"></i>
            <div className="aherd_ineer">Referral</div>
          </div>
        </NavLink>
        {/* <NavLink to="/staking" className="css-z87e9z">
          <div className="css-10j588g ">
            {" "}
            <i class="ri-plant-line"></i>
            <div className="aherd_ineer">Staking</div>
          </div>
        </NavLink> */}
        <NavLink to="/swap" className="css-z87e9z">
          <div className="css-10j588g ">
            {" "}
            <i class="ri-exchange-funds-fill"></i>
            <div className="aherd_ineer">Swap</div>
          </div>
        </NavLink>
        <NavLink to="/deposit" className="css-z87e9z">
          <div className="css-10j588g ">
            {" "}
            <i class="ri-bank-fill"></i>
            <div className="aherd_ineer">Deposit</div>
          </div>
        </NavLink>
        <NavLink to="/withdraw" className="css-z87e9z">
          <div className="css-10j588g ">
            {" "}
            <i class="ri-currency-fill"></i>
            <div className="aherd_ineer">Withdraw</div>
          </div>
        </NavLink>
      </div>
    </div>
  );
}

export default Home;
